<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="Projects"
          :breadcrumb="[{ label: 'Dashboard' }, { label: 'Projects' }]"
        >


        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12 col-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div class="card custom-card">
              <div class="card-body">

                <div class="row">
                  <div class="col text font">

                    <h4><strong style="color: #00364f; font-weight: bolder">Projects</strong></h4>
                  </div>
                  <div class="col text-end cus-p-1">
                   
                <button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>
                          &nbsp;

                  </div>
                    <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" >

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row ">
                  <div class="d-md-none d-xl-none d-block col-3 text font cus-p-1">

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                    <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>
               
                </div>
                <!-- start list area  -->
                <!-- start list area  -->
                <div class="row">
               
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
             
                  >
                    <div class="container-fluid table-scroll" style="margin-left: -5px">
                      <table class="table table-hover table-sm text font">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th class="text-truncate">Code</th>
                          <th class="text-truncate">Date</th>
                          <th class="text-truncate">Retailer</th>
                          <th class="text-truncate">Client Name</th>
                          <th class="text-truncate">Service</th>
                          <!-- <th class="text-truncate">Revenue</th> -->
                          <th class="text-truncate">Invoice</th>
                          <th class="text-truncate">Progress</th>
                          <th class="text-truncate">Status</th>
                          <!-- <th class="text-truncate text-end">Action</th> -->
                        </tr>
                        <tbody class="text-truncate">
                          <tr v-for="(project, index) in clientServices" :key="index">
                            <td>
                              <span v-if="project.client">{{
                                project.clientServiceNo
                              }}</span>
                            </td>
                            <td>
                              <span v-if="project">{{ dateTime(project.date) }}</span>
                            </td>
                            <td>
                              <span v-if="project.retailer"
                                >{{ project.retailer.name }}</span
                              >
                            </td>

                            <td class="text-truncate">
                              <span v-if="project.client">
                                {{ project.client.fname }}
                                {{ project.client.lname }}</span
                              >
                            </td>
                            <td class="text-truncate">
                              <span v-if="project.service">
                                {{ project.service.name }}</span
                              >
                            </td>
                            <!-- <td class="text-truncate">
                              {{ project.master_distributer_commission + project.master_distributer_commission_tds }}
                            </td> -->
                              <td class="text-truncate">
                              {{ project.serviceChargeGst + project.serviceCharge }}
                            </td>
                            <td class="text-truncate">
                              <div class="progress" style="height: 7px">
                                <!-- ${progressForProject(
                                    project.client_service_professional_step
                                  )} -->
                                <div
                                  :style="`background-color: #f21300; width:${progressForProject(
                                    project.client_service_professional_step
                                  )}%`"
                                  class="progress-bar"
                                  role="progressbar"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </td>
                            <td class="text-truncate">
                              <p
                                  v-if="project.status == 'assigned'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #00364f;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>
                                <p
                                  v-if="project.status == 'completed'"
                                  class="btn btn-sm"
                                  style="
                                    width: 90px;
                                    background-color: #008000;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>

                                <p
                                  v-if="project.status == 'pending'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #cdcdcd;
                                    color: white;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>New</b>
                                </p>
                                <p
                                  v-if="project.status == 'processed'"
                                  class="btn btn-sm"
                                  style="
                                    width: 80px;
                                    background-color: #ffaa00;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>
                                <p
                                  v-if="project.status == 'hold'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #8f8d8e;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>
                                <p
                                  v-if="project.status == 'rejected'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #00364f;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>
                                <p
                                  v-if="project.status == 'cancelled'"
                                  class="btn btn-sm"
                                  style="
                                    width: 80px;
                                    background-color: #f21000;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>
                                <p
                                  v-if="project.status == 'upgrade'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #ee0def;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.status }}</b>
                                </p>
                            </td>
                            <!-- <td class="text-truncate text-end"> -->
                            
                              <!-- <div class="btn-group btn-group-sm" role="group"> -->
                                <!-- <router-link
                                  :to="`/distributer/project-detailes/${project.id}`"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link> -->
                                <!-- <router-link
                                  :to="`/distributer/commision-rate/${project.id}`"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #f21300;
                                  "
                                >
                                  <i class="fa fa-inr" aria-hidden="true"></i>
                                </router-link> -->
                              <!-- </div> -->
                            <!-- </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
              <div class="card-footer">
                    <div class="row">

                      <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                        <p class="mb-0">
                          Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                        </p>
                      </div>
                      <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                        <ul class="pagination  justify-content-center">
                          <li class="page-item" @click="loadClients(null, 'first')">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                            </a>
                          </li>
                          <li class="page-item" @click="loadClients(null, 'prev')">
                            <a class="page-link" href="#">Previous</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page > 3"
                            @click="loadClients(pagination.current_page - 3)">
                            <a class="page-link" href="#">{{
                              pagination.current_page - 3
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page > 2"
                            @click="loadClients(pagination.current_page - 2)">
                            <a class="page-link" href="#">{{
                              pagination.current_page - 2
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page > 1"
                            @click="loadClients(pagination.current_page - 1)">
                            <a class="page-link" href="#">{{
                              pagination.current_page - 1
                            }}</a>
                          </li>
                          <li class="active page-item" @click="loadClients(pagination.current_page)">
                            <a class="page-link" href="#">{{ pagination.current_page }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                            @click="loadClients(pagination.current_page + 1)">
                            <a class="page-link" href="#">{{
                              pagination.current_page + 1
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                            @click="loadClients(pagination.current_page + 2)">
                            <a class="page-link" href="#">{{
                              pagination.current_page + 2
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                            @click="loadClients(pagination.current_page + 3)">
                            <a class="page-link" href="#">{{
                              pagination.current_page + 3
                            }}</a>
                          </li>
                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                          <li class="page-item">
                            <a class="page-link" href="#">...</a>
                          </li>

                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                            @click="loadClients(pagination.last_page - 3)">
                            <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                            @click="loadClients(pagination.last_page - 2)">
                            <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                            @click="loadClients(pagination.last_page - 1)">
                            <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                          </li>

                          <li class="page-item" @click="loadClients(null, 'next')">
                            <a class="page-link" href="#">Next</a>
                          </li>
                          <li class="page-item" @click="loadClients(null, 'last')">
                            <a class="page-link" href="#">&raquo;</a>
                          </li>
                        </ul>
                      </div>

                      <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->







    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>







  
    <!-- Right Sidebar starts -->
    <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



            <ul class="list-group list-group-flush">
  <li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="clientServiceNo" class="form-check-input" type="radio" name="flexRadioDefault" id="clientServiceNo1">
  <label class="form-check-label" for="clientServiceNo1">
    Code
  </label>
</div>
  </li>
  <li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="date" class="form-check-input" type="radio" name="flexRadioDefault" id="date">
  <label class="form-check-label" for="date">
    Date
  </label>
</div>
</li>
  <!--   <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="dueDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Due Date
  </label>
</div>
</li> -->
<!-- <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="completionDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Completion Date
  </label>
</div>
  </li> -->
  <!-- <li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="client_id" class="form-check-input" type="radio" name="flexRadioDefault" id="client_id">
  <label class="form-check-label" for="client_id">
    Client PanNo
  </label>
</div></li> -->

<li class="list-group-item"><div class="form-check">
  <input v-model="filterType" value="status" class="form-check-input"  type="radio" name="flexRadioDefault" id="status">
  <label  @click="valueNullFilter()" class="form-check-label" for="status">
    Status
  </label>
</div></li>

<li class="list-group-item"><div class="form-check">
  <input v-model="filterType" value="service_id" class="form-check-input"  type="radio" name="flexRadioDefault" id="service_id">
  <label class="form-check-label" for="service_id">
    Service
  </label>
</div></li>
<li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio" name="flexRadioDefault" id="all">
  <label class="form-check-label" for="all">
    All
  </label>
</div>
  </li>
</ul>


<div class="pt-1 pb-1">
  <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
 <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 <input type="text" v-if="filterType == 'clientServiceNo'" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 



 <select v-model="filterValue" v-if="filterType == 'status'" class="form-select" aria-label="Default select example">
  <option selected disabled>Please Select...</option>
  <option value="pending">New</option>
  <option value="assigned">Assign</option>
  <option value="completed">Complete</option>
  <option value="cancelled">Cancelled</option>
  <option value="accepted">Accepted</option>
  <option value="rejected">Rejected</option>
  <option value="upgrade">Upgrade</option>
  <option value="processed">Processed</option>

</select>


<select v-model="filterValue" v-if="filterType == 'service_id'" class="form-select" aria-label="Default select example">
  <option selected disabled>Please Select...</option>
  <option v-for="(service, index) in services" :key="index" :value="service.id">{{service.name}}</option>


</select>

</div>

<button v-if="filterType != 'client_id'" @click="loadClients()" type="button" class="btn btn-success btn-sm">Success</button>



           
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

//
<script>
import moment from "moment";

import Spinner from "../../../components/distributer/comman/Spinner.vue";
import Banner from "../../../components/distributer/comman/Banner.vue";

// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "DistributerProjects",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      services: [],
      filterValue:'',
      filterType:'',
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,

      loading: false,
      groupedStatus: "individual",
      statusQueryArray: [],
      filtereGoupbtn: "false",
      pageHeading: "PROJECTS",
      statusQuery: "",
      clientServices: [],
      counter: 0,
    };
  },
  methods: {
    findRecordeByPanId(e) {

this.$axios
  .get(`distributer/client?pan=${e.target.value}`, {
    headers: { Authorization: "Bearer " + localStorage.accessToken },
  })
  .then((res) => {
    this.filterValue = "0"
if (res.data.data.data[0]) {
      this.filterValue = res.data.data.data[0].id


      
    }
    this.loadClients()
  });
},
servicesLoad() {

this.$axios
  .post(`allservice`,{}, {
    headers: { Authorization: "Bearer " + localStorage.accessToken },
  })
  .then((res) => {
this.services = res.data.data
console.log(res.data.data)

  });
},
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadClients();
    },
    progressForProject(tasks) {
      var complete = 0;
      for (var i = 0; i < tasks.length; i++) {
        if (tasks[i].status == "complete") {
          complete += 1;
        }
      }
      var n = 100 / tasks.length;
      return n * complete;
    },
    dateTime(value) {
      // console.log(value);
      return moment(value).format("DD-MM-YYYY");
    },
    filtereGoup() {
      this.filtereGoupbtn = "true";
    },
    valueNullFilter(){
  this.filterValue = "";
},
    statusValueAssign(value) {
      if (value == "group") {
        this.groupedStatus = "grouped";
      } else {
        this.groupedStatus = "individual";
      }
    },
    statusArrayAssign(value) {
      if (value == "new") {
        this.statusQueryArray = ["pending", "assigned", "rejected"];
        //  console.log( this.statusQueryArray)
      } else {
        this.statusQueryArray = ["hold", "upgrade", "processed"];
        // console.log( this.statusQueryArray)
      }
    },
    statusWiseProject(value) {
      if (value == "new") {
        this.pageHeading = "NEW PROJECTS";
      }
      this.statusQuery = value;
      this.loadClients();
    },
    pushStatusvalueInArray(event, value) {
      console.log(event.target.checked);
      if (event.target.checked == true) {
        this.statusQueryArray.push(value);
        console.log(this.statusQueryArray);
        this.loadClients();
      } else if (event.target.checked == false) {
        this.statusQueryArray.splice(this.statusQueryArray.indexOf(value), 1);
        console.log(this.statusQueryArray);
        this.loadClients();
      }
    },

    loadClients(page = null, pg = null) {
      console.log(page);
      console.log(pg);

      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
      



        if( this.filterType == 'all'){
            this.filterValue = ''
            this.filterType = ''
          }
          


          if(this.filterValue != null && this.filterType != null){
          if(this.filterType == 'date'){
            pageUrl += `distributer/clientservice?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
        
          }else{
   pageUrl += `distributer/clientservice?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
          
          }
           }else{
            pageUrl += `distributer/clientservice?per_page=${this.per_page}`;
           }



      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      
      
        this.loading = true;
        this.$axios
          .get(`${pageUrl}`, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            this.clientServices = res.data.data.data;

            console.log('===============================================')
            console.log(this.clientServices)
            console.log('===============================================')
            this.pagination = res.data.data;



            this.filterValue = ""
          })
          .finally(() => (this.loading = false));
     
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.distributerPageTitles.clientsService
    console.log(this.$store.state.distributerPageTitles.clientsService)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadClients();
      this.servicesLoad();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #fefeff;
  border-color: #f21003;
  border-width: 3px;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

th {
  width: 200px;
}

.table-scroll {
  overflow-y: auto;
}

.form-select {
  border: 1px solid #00364f;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
.form-checkbox {
  width: 25px;
  margin-top: 5px;
}

.form-check-input:checked {
    background-color: #ffffff;
    border-color: #f21000;
    border-width: 5px;
}
.form-check-input:not(:disabled):checked {
    box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}




.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 10px 10px 0px 10px;
}
</style>
